/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React, { useContext } from 'react';
import { graphql, PageProps } from 'gatsby';
import ContactDetail from '@/components/Contact/ContactDetail';
import { Context } from '@/components/Layout';

const ContactPage: React.FC<PageProps<GatsbyTypes.ContactPageQuery>> = ({
  location,
  data,
}) => {
  const loading = useContext(Context);
  const agreementFlg = location.state ? location.state : false;
  return agreementFlg
    ? !loading && <ContactDetail data={data} />
    : typeof window !== `undefined` &&
        (window.location.href = `/contact/${data.microcmsContact.contactId}/`);
};

export default ContactPage;

export const query = graphql`
  query ContactPage($ContactPageId: String!) {
    microcmsContact(contactId: { eq: $ContactPageId }) {
      title
      contactId
      button
      tayori
      display
    }
  }
`;
