// ■コンポーネント説明
// お問い合わせ詳細表示用コンポーネント

import React, { useEffect } from 'react';
import SEO from '@/components/seo';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';

export interface ContactDetailProps {
  data: GatsbyTypes.ContactPageQuery;
}

const ContactDetail: React.FC<ContactDetailProps> = (props) => {
  const { data } = props;

  return (
    <>
      <SEO title={`${data.microcmsContact.title} | お問い合わせ`} />
      <div
        style={{
          marginTop: `14rem`,
          width: `100%`,
          height: `1200px`,
          overflow: `auto`,
          WebkitOverflowScrolling: `touch`,
        }}
      >
        <iframe
          id="contact"
          title={data.microcmsContact.button}
          src={`https://nrinetcom.tayori.com/form/${data.microcmsContact.tayori}`}
          width="100%"
          height="100%"
        />
      </div>
      <UIBreadcrumb
        UpperItem={[[`Contact`, `/contact`]]}
        CurrentItem={data.microcmsContact.title}
      />
    </>
  );
};

export default ContactDetail;
